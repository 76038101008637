import React, { FC, useState } from "react";
import { format, parseISO } from "date-fns";

import { IOcorrencia } from "@deltasge/marauders-map";

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DotsVertical as MoreIcon,
  Pencil as EditIcon,
  Delete as DeleteIcon,
} from "mdi-material-ui";

import { useMobile } from "hooks/useMobile";

export const ListItem: FC<{
  item: IOcorrencia;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
}> = ({ item, handleEdit, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isSm = useMobile("sm");
  const isMd = useMobile("md");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="inherit" component="p">
          {item.tipoOcorrencia?.nome.rUpper()}
        </Typography>
        <Typography variant="caption" component="p">
          {item.historico}
        </Typography>
      </TableCell>
      <TableCell>{item.aluno?.nome.rUpper()}</TableCell>
      {!isSm && !isMd && (
        <TableCell>
          {item.materia?.nome.rUpper() ?? "Independente da matéria"}
        </TableCell>
      )}
      {!isSm && (
        <TableCell align="right">
          {format(parseISO((item.data ?? "") as string), "dd/MM/yyyy")
            .concat(" ")
            .concat(item.hora?.trim() ?? "")}
        </TableCell>
      )}
      {!isSm && !isMd && (
        <TableCell align="right">{item.restrita ? "Não" : "Sim"}</TableCell>
      )}
      <TableCell align="right">
        {!isSm && !isMd && (
          <>
            <Tooltip title="Editar">
              <IconButton onClick={() => handleEdit(item.id ?? 0)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton onClick={() => handleDelete(item.id ?? 0)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {(isSm || isMd) && (
          <>
            <IconButton onClick={handleClick}>
              <MoreIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleEdit(item.id ?? 0);
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleDelete(item.id ?? 0);
                }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Excluir</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
