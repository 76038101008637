import { Environment } from "configs/Environment";

// https://semver.org/

// MAJOR version when you make incompatible API changes
const MAJOR = 1;

// MINOR version when you add functionality in a backwards compatible manner
const MINOR = 0;

// PATCH version when you make backwards compatible bug fixes
const PATCH = Environment.BUILD_NUM;

export const APP_VERSION = `${MAJOR}.${MINOR}.${PATCH}`;
