import { createReducer, PayloadAction } from "@reduxjs/toolkit";

import { ActionTypes, IVersaoApp } from "./types";

export const reducers = createReducer(
  {
    api: "",
    model: "",
    service: "",
  },
  {
    [ActionTypes.SET]: (state, action: PayloadAction<IVersaoApp>) =>
      action.payload,
  }
);
