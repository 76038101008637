import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";
import { IPeriodoEtapa } from "@deltasge/marauders-map";
import { useAppSelector } from "store";
import { format, parseISO } from "date-fns";

interface Props {
  selecionado?: IPeriodoEtapa;
  etapas?: IPeriodoEtapa[];
  onClick: (etapa?: number) => void;
  render: "accordion" | "autocomplete";
  mostrarTodos: boolean;
}

export const FilterEtapa: FC<Props> = ({
  selecionado,
  onClick,
  etapas,
  render,
  mostrarTodos,
}) => {
  const {
    escola,
    configuracoes: {
      configOnline: { configuracoes },
    },
  } = useAppSelector((state) => state);

  const [panel, setPanel] = useState(false);

  const handleClick = (etapa?: number) => {
    onClick(etapa);
    if (render == "accordion") setPanel(false);
  };

  let label = `INDEPENDENTE DO ${escola.nomeEtapa} - 01/01/${configuracoes.ano_site} à 31/12/${configuracoes.ano_site}`;
  if (!mostrarTodos && !selecionado) {
    label = `SELECIONE UM ${escola.nomeEtapa?.toUpperCase()}`;
  } else if (
    selecionado &&
    selecionado.dataEtapaIni &&
    selecionado.dataEtapaFim
  ) {
    const dataIni = format(parseISO(selecionado.dataEtapaIni), "dd/MM/yyyy");
    const dataFim = format(parseISO(selecionado.dataEtapaFim), "dd/MM/yyyy");
    label = `${selecionado.etapa} º ${escola.nomeEtapaAbreviado} - ${dataIni} à ${dataFim}`;
  }
  if (render == "accordion") {
    return (
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Etapa
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {label}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {mostrarTodos && (
              <ListItemButton onClick={() => handleClick()}>
                <ListItemIcon>
                  <Radio checked={undefined == selecionado} />
                </ListItemIcon>
                <ListItemText
                  primary="INDEPENDENTE DA ETAPA"
                  secondary={`01/01/${configuracoes.ano_site} à 31/12/${configuracoes.ano_site}`}
                />
              </ListItemButton>
            )}
            {etapas?.map((etapa) => (
              <ListItemButton
                key={`filter-etapa-${etapa.etapa}`}
                onClick={() => handleClick(etapa.etapa)}
              >
                <ListItemIcon>
                  <Radio checked={etapa.etapa == selecionado?.etapa} />
                </ListItemIcon>
                <ListItemText
                  primary={`${etapa.etapa}º ${escola.nomeEtapa}`}
                  secondary={
                    etapa.dataEtapaFim &&
                    etapa.dataEtapaIni &&
                    `${format(
                      parseISO(etapa.dataEtapaIni),
                      "dd/MM/yyyy"
                    )} à ${format(parseISO(etapa.dataEtapaFim), "dd/MM/yyyy")}`
                  }
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Autocomplete
      sx={{ m: 1 }}
      disablePortal
      id="filter-etapa"
      options={etapas ?? []}
      value={selecionado}
      renderInput={(params) => <TextField {...params} label="Etapa" />}
      getOptionLabel={(option) => `${option.etapa}º ${escola.nomeEtapa}`}
      onChange={(event, value) => handleClick(value?.etapa)}
      noOptionsText="Selecione uma série"
      clearText="Todos as turmas"
      openText="Selecione uma turma"
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            key={option.id}
            primary={`${option.etapa}º ${escola.nomeEtapa}`}
            primaryTypographyProps={{
              noWrap: true,
            }}
          />
        </ListItem>
      )}
    />
  );
};
