import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { setup, IAxiosCacheAdapterOptions } from "axios-cache-adapter";
import { IUser } from "@deltasge/marauders-map";
import { Environment } from "./Environment";
import { set as setUsuario } from "store/modules/auth/usuario";
import { store } from "store";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";

const limit = 5;
let count = 0;

interface MemoryStore {
  store: object;
  setItem: (key: string, value: object) => object;
  getItem: (key: string) => object;
  removeItem: (key: string) => void;
  clear: () => void;
  length: () => number;
}

interface MyIAxiosCacheAdapterOptions extends IAxiosCacheAdapterOptions {
  uuid: string;
}

export const refreshUser = async (usuario: IUser): Promise<IUser> => {
  const codigoZeroEsqueda = usuario?.escolaId.toString().padStart(4, "0");
  const { data }: AxiosResponse<IUser> = await Axios.post(
    `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/auth/${Environment.KEY_APP_AUTH}/refresh`,
    null,
    {
      headers: { Authorization: `Bearer ${usuario?.token}` },
    }
  );

  if (!data.token) {
    throw new Error("Erro ao renovar o token de acesso.");
  }

  await Axios.put<IUser>(
    `${Environment.CONFIG_DELTA_API_URL}/usuarios/usuario/${data.deltaId}`,
    {
      token: data.token,
      id: data.id,
      nome: data.nome,
      email: data.email,
      aplicativos: data.aplicativos,
      codigo: data.codigo,
      escolaId: codigoZeroEsqueda,
      deltaId: data.deltaId,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  return data;
};

export const interceptorError = async (
  error: AxiosError,
  defaultApi: AxiosInstance
) => {
  try {
    const { config, response, message } = error;
    const {
      usuario: { usuario },
    } = store.getState();

    if (
      response?.status === 401 &&
      (message.toLowerCase().includes("token") ||
        response?.statusText == "Unauthorized" ||
        response?.data?.error == "jwt expired") &&
      usuario
    ) {
      if (limit <= count) {
        throw new Error("Erro ao renovar o token. Tente novamente mais tarde");
      }

      const data = await refreshUser(usuario);
      setUsuario(data);
      setToken(data.token);
      if (config.headers) {
        config.headers.Authorization = `Bearer ${data.token}`;
      }

      return defaultApi(config);
    }
    count = 0;
    return Promise.reject(error);
  } catch (error) {
    count += 1;
    snack.error(getError(error));
    return Promise.reject(error);
  }
};

export const interceptorGenericError = (error: AxiosError) => {
  error.message =
    error?.response?.data?.error ?? error.message ?? error.toString();
  return Promise.reject(error);
};

const invalidate = async (
  config: IAxiosCacheAdapterOptions,
  request: AxiosRequestConfig<any>
) => {
  if (request.clearCacheEntry) {
    await (config.store as MemoryStore).removeItem(
      (config as MyIAxiosCacheAdapterOptions).uuid
    );
  }
};

export const api = setup({
  baseURL: Environment.API_URL,
  cache: {
    // debug: true,
    maxAge: Environment.MILLISECONDS_IN_A_SECONDS,
    exclude: { query: false },
    invalidate,
  },
});

export const apiRelatorio = setup({
  baseURL: Environment.RELATORIO_API_URL,
  cache: {
    // debug: true,
    maxAge: Environment.MILLISECONDS_IN_A_SECONDS,
    exclude: { query: false },
    invalidate,
  },
});

export const apiConfig = setup({
  baseURL: Environment.CONFIG_DELTA_API_URL,
});

export const apiPush = setup({
  baseURL: Environment.PUSH_DELTA_API_URL,
});

export const setToken = (token?: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiRelatorio.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiConfig.defaults.headers.common.Authorization = `Bearer ${token}`;
  apiPush.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setBaseUrl = (escolaId: string) => {
  api.defaults.baseURL = `${Environment.API_URL}/api/${escolaId}`;
  apiRelatorio.defaults.baseURL = `${Environment.RELATORIO_API_URL}/api/${escolaId}`;
};

api.interceptors.response.use(
  (success) => success,
  (error) => interceptorError(error, api)
);
apiRelatorio.interceptors.response.use(
  (success) => success,
  (error) => interceptorError(error, apiRelatorio)
);
apiConfig.interceptors.response.use(
  (success) => success,
  (error) => interceptorError(error, apiConfig)
);
apiPush.interceptors.response.use(
  (success) => success,
  (error) => interceptorError(error, apiPush)
);

api.interceptors.response.use((success) => success, interceptorGenericError);
apiRelatorio.interceptors.response.use(
  (success) => success,
  interceptorGenericError
);
apiConfig.interceptors.response.use(
  (success) => success,
  interceptorGenericError
);
apiPush.interceptors.response.use(
  (success) => success,
  interceptorGenericError
);
